import * as React from "react"


  const EmbeddedVimeo = ({videoUrl}) => (
      <div
      style={{
          padding:"56.25% 0 0 0",
          position: "relative",
      }}

      >
      <iframe 
          src={videoUrl}
          frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen={true}
          style={{
              position:"absolute", top: 0, left: 0, width: "100%", height: "100%",
          }}
          >
      </iframe>
    </div>
  )

  
  export function VideoCards({videos}) {

    return (
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {
              videos.map((video, index) => (
                <div key={index} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    {/* <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" /> */}
                    <EmbeddedVimeo videoUrl={video.videoUrl}/>
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-600">
                        <a href={video.category.href} className="hover:underline">
                          {video.category.name}
                        </a>
                      </p>
                      <a href={video.href} className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">{video.title}</p>
                        <p className="mt-3 text-base text-gray-500">{video.description}</p>
                      </a>
                    </div>
                    <div>
                    {
                      video.tags.map(t=>(
                        <span className="inline-flex mx-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          {t}
                        </span>
                      ))
                    }
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="flex-shrink-0">
                        <a href={video.author.href}>
                          <span className="sr-only">{video.author.name}</span>
                          <img className="h-10 w-10 rounded-full" src={video.author.imageUrl} alt="" />
                        </a>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                          <a href={video.author.href} className="hover:underline">
                            {video.author.name}
                          </a>
                        </p>

                        <div className="flex space-x-1 text-sm text-gray-500">
                          <time dateTime={video.datetime}>{video.date}</time>
                          <span aria-hidden="true">&middot;</span>
                          <span>{video.videoDuration} video</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
          </div>
    )
  }
  