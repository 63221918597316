import * as React from "react"
import {useEffect, useState} from "react"
import { Nav } from "../components/nav"
import { VideoCards } from "../components/videoCards"
import { SearchInput } from "../components/searchInput"
// markup

/* This example requires Tailwind CSS v2.0+ */
export default function VideosPage() {

  const [searchText, setSearchText] = useState("")
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [videos, setVideos] = useState([])
  const [filtered, setFiltered] = useState([])
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])

  useEffect(()=>{
    fetch('https://gttpio.github.io/gttp.github.io/videos.json')
    .then((response)=>response.json())
    .then((result)=>{
        setIsLoaded(true)
        setVideos(result)
        setFiltered(result)
        let tagLists = []
        tagLists = result.map(v=> v.tags)
        let allTags = [].concat.apply([],tagLists)
        setTags(allTags.filter((value, index, self)=>(self.indexOf(value)===index)))
      }, (error) => {
        setIsLoaded(true)
        setError(error)
      }
    )}, [])

  useEffect(()=>{
    console.log(searchText)
    let result = videos
    if (searchText !== ""){
      result = videos.filter(v=>v.title.toLowerCase().includes(searchText.toLowerCase()))
    }
    if(selectedTags.length > 0) {
      result = result.filter(v=> selectedTags.every(t => v.tags.includes(t)))  
    }

    setFiltered(result)
  }, [searchText, selectedTags])

  // useEffect(()=>{
  //   let allTags = []
  //   allTags = videos.map(v=> [...allTags, ...v.tags])
  //   setTags(allTags.filter((value, index, self)=>(self.indexOf(value)===index)))
  // }, [videos])

  const handleFilterButton = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t=>(t!==tag)))
    } else {
      let newList = [...selectedTags]
      newList.push(tag)
      setSelectedTags(newList)
    }
  }

  const onSearchChange = (event) => {setSearchText(event.target.value)}


  return (
    <div>
      <Nav/>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:py-4 sm:px-6 lg:px-8">
          <div className="md:flex md:justify-between md:py-4">
            <p className="
                block md:inline-block  
                text-4xl text-gray-900 sm:text-5xl lg:text-6xl
                sm:tracking-tight
                px-4 md:px-0
                py-4 md:py-0
                "
                >Videos</p>
            <div className="
                  inline-block sm:block
                  px-4
                  py-4 
                  "
            >
              <SearchInput onChange={onSearchChange}/>
            </div>
          </div>
          <div className="flex justify-left">
            <p className="inline-flex">Filter by topic:</p>
            {
              tags.map((tag)=>{
                return(
                  <button
                  type="button"
                  value={tag}
                  key={tag}
                  onClick={()=>{handleFilterButton(tag)}}
                  className={`inline-flex items-center px-3 py-2 mb-2 mx-4 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white
                  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${selectedTags.includes(tag)? "bg-blue-100" :null}
                  `}
                  >{tag}</button>    
                )
              })
            }
          </div>
          <div className="py-1 overflow-y-scroll border-t-2">
            {error ? <div>Error: {error.message}</div>: !isLoaded? <div>Loading . . . </div> : <VideoCards videos={filtered}/>}
          </div>
        </div>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  )
  
}
